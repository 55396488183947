import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { ListTLink, animateObjects, newContent} from "../../components/Layout";
import SEO from '../../components/seo';
import GiveContentTrans from '../../components/GiveContentTrans';

import Layout from '../../components/Layout';

/* // Create component in same file. We use it just here so keep it in same file. Otherwise export etc,.
function WorkPreview({ work }) {
  return (
    <div>
      <Link to={`/work/${work.slug}`}>
        <h2>
          <span className="mark">{work.title}</span>
        </h2>
      </Link>
     
      <Img fluid={work.image.asset.fluid} alt={work.title} />
    </div>
  );
} */

// markup
// const IndexPage = ({ data }) => {

function AboutPage() {
  return (
    <Layout>
    <main className="open-service-page outer-grid">
      <GiveContentTrans language="et">
        <SEO
          title="E-poodide erilahendused"
          description="Unikaalse kujundusega kasutajasõbralikud ning isikupärased e-poed tugevatele brändidele. Tipptasemel disain. "
          image="/pagecover-teenused.jpg"
        />
      </GiveContentTrans>
      <GiveContentTrans language="en">
        <SEO
          title="E-commerce solutions"
          description="Focus on unique e-commerce designs and user-friendly interfaces, we create online stores for strong brands that stand out from the crowd and drive results."
          image="/pagecover-en-default.jpg"
        />
      </GiveContentTrans>
      <section className="hero">
        <GiveContentTrans language="et">
          <h1>E-poodide erilahendused</h1>

          <div className="page-copy">
            <p>
              E-kaubandus ei ole meie jaoks võõras. Üks esimesi Give projekte
              oli veebipood. Oleme spetsialiseerunud butiik e-poodide
              erilahendustele. Lühidalt tähendab see seda, et disainime ja arendame pisemaid ja
              keskmise suurusega ning keerukusega e-poode unikaalse UI/UX disainiga.
              Meil on tähtis, et kliendid ei rahulduks valmis kujundusega
              ja sooviksid, et nende toode või bränd oleks esindatud unikaalses
              vormis.
            </p>
            <p>Ostu ja makselahendustele teeme ka UI/UX disaini. <ListTLink
              to="/teenused/ui-ux-disain/"
              exit={{
                length: 1,
                zIndex: 2, // exit default is 0
                trigger: ({ exit, e, node }) => animateObjects(exit, node),
              }}
              entry={{
                delay: 0.5,
                length: 0.6,
                trigger: ({ entry, node }) => newContent(node),
              }}
            >
             Vaata UI/UX disain
            </ListTLink>.</p>
          </div>
        </GiveContentTrans>
        <GiveContentTrans language="en">
          <h1>E-commerce solutions</h1>

          <div className="page-copy">
            <p>
              One of the first Give projects was an online store. We specialize
              in special solutions for boutique e-commerce. In short, this means
              that we create unique designs for smaller and medium-sized, and
              complex e-commerce stores. It is important to us that customers do
              not settle for ready-made designs and that their product or brand
              is represented in a unique form.
            </p>
          </div>
        </GiveContentTrans>
      </section>

      <GiveContentTrans language="et">
        <div className="img-100">
          <StaticImage
            src="../../assets/e-poe-erilahendus-1.jpg"
            alt="VueJs e-poe erilahendus pealeht"
            layout="fullWidth"
          />
        </div>

        <section className="description-section inner-grid">
          <div className="content-col page-copy">
            <h2>E-poe arendus</h2>
            <p>
              Veebipoe otsast lõpuni ise programmeerimine on üldjuhul väga pikk
              ja keerukas protsess mis enamasti ennast ei õigusta. Sisuhalduse
              süsteemidena oleme välja valinud Wordpressi Woocommerce ja
              Shopify. Kujunduslahendusi oleme loonud ka teistele platvormidele.
            </p>

            <p>
              Paneme teie e-poe suhtlema raamatupidamis- või
              laohaldustarkvaraga. Automatiseeritud tööprotsessid hoiavad kokku
              aega ja kulusid. Läbi protsesside automatiseerimise väheneb vigade
              hulk ja suureneb kliendirahulolu.
            </p>
          </div>
        </section>

        <div className="img-full">
          <StaticImage
            src="../../assets/e-poe-erilahendus-2.jpg"
            alt="VueJs e-poe erilahendus ostukorv"
            layout="fullWidth"
          />
        </div>
      </GiveContentTrans>
      <GiveContentTrans language="en">
        <div className="img-100">
          <StaticImage
            src="../../assets/e-poe-erilahendus-1.jpg"
            alt="VueJs front-end for ecommerce shop"
            layout="fullWidth"
          />
        </div>

        <section className="description-section inner-grid">
          <div className="content-col page-copy">
            <h2>E-commerce development</h2>
            <p>
              We usually do not recommend building an online store from scratch
              as it can be a very long and complex process that often does not
              justify the effort. As content management systems, we have chosen
              Wordpress Woocommerce and Shopify. We have also created design
              solutions for other platforms.
            </p>

            <p>
              We can make your e-commerce store communicate with accounting or
              inventory management software. Automated workflows save time and
              costs. By automating processes, the number of errors decreases and
              customer satisfaction increases.
            </p>
          </div>
        </section>

        <div className="img-full">
          <StaticImage
            src="../../assets/e-poe-erilahendus-2.jpg"
            alt="Shopping cart UI design for custom VueJS front-end"
            layout="fullWidth"
          />
        </div>
      </GiveContentTrans>
    </main>
    </Layout>
  
  );
}

export default AboutPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;